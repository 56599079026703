<template>
    <div>
        <!-- Start Footer Area  -->
        <footer v-if="data.showFooter" class="rn-footer footer-style-default variation-two">
            
            <div class="footer-top">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-12 col-sm-12 col-12 tpwlkj">
                            <div class="rn-footer-widget" style="text-align: center;">
                                <img src="@/assets/images/tpmy-footer-logo.png" alt="" style="margin-bottom: 35px; max-height: 70px;">
                                <div class="tpmy">牧运千万里，天蓬零距离</div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 col-12" style="display: flex; align-items: center;">
                            <div class="rn-footer-widget" style="padding-left: 70px;">
                                <div class="inner">
                                    <ul class="footer-link link-hover">
                                        <li>全国服务电话:400-066-0691</li>
                                        <li>工作时间:周一至周五 08:20-18:00</li>
                                        <li>地址:内蒙古赤峰市中关村信息科技谷A10座5F</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div class="rn-footer-widget">
                                <div class="inner">
                                    <div class="footer-images">
                                        <div class="footer-images-item">
                                            <img src="@/assets/images/downloadCode.png" alt="">
                                            <div>下载司机端</div>
                                        </div>
                                        <div class="footer-images-item">
                                            <img src="@/assets/images/downloadCode.png" alt="">
                                            <div>下载货主端</div>
                                        </div>
                                        <div class="footer-images-item">
                                            <img src="@/assets/images/gzh.png" alt="">
                                            <div>关注公众号</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Newsletter v-if="data.showNewsletter"/>
        </footer>
        <!-- End Footer Area  -->

        <Copyright v-if="data.showCopyright"/>

        <ScrollTop/>
    </div>
</template>

<script>
    import Copyright from './Copyright'
    import Icon from '../../icon/Icon'
    import ScrollTop from './ScrollTop'
    import Logo from '../../elements/logo/Logo'
    import Newsletter from './Newsletter'

    export default {
        name: 'Footer',
        components: {Newsletter, Logo, ScrollTop, Icon, Copyright},
        props: {
            data: {
                default: null
            }
        }
    }
</script>

<style lang="scss">
    .tpmy {
        font-family: 'ZhiMangXing-Regular, ZhiMangXing';
        color: #ffffff;
        font-size: 20px;
    }
    .footer-top {
        background-color: var(--bgcolor-footer-top);
    }
    .footer-link {
        li {
            color: #ffffff !important;
            font-size: var(--font-size-b3) !important;
        }
    }
    .footer-images {
        font-size: var(--font-size-b3);
        display: flex;
        align-items: center;
        justify-content: center;
        .footer-images-item {
            text-align: center;
            margin: 5px;
            color: #ffffff !important;
        }
        img {
            width: 100px;
            height: 100px;
        }
    }
</style>