<template>
    <ul class="mainmenu">
        <li><router-link to="/">首页</router-link></li>
        <li><router-link to="/about">关于我们</router-link></li>
        <li><router-link to="/download">软件下载</router-link></li>
        <li><router-link to="/recruit">人才招聘</router-link></li>
        <li><router-link to="/contact">联系我们</router-link></li>
    </ul>
</template>

<script>
    import Icon from "../../icon/Icon";
    export default {
        name: 'Nav',
        components: {Icon}
    }
</script>