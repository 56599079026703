<template>
    <Layout>
        <div style="height: 20px;"></div>
        <div class="demo-slider-area slider-area bg-transparent slider-style-1">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner text-center">
                            <div class="" data-aos="fade-up">
                                <img class="w-100" src="@/assets/images/contact/contact.png" alt="Corporate Image"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Start Elements Area -->
        <div class="demo-slider-area slider-area bg-transparent slider-style-1">
            <div class="container contact-container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner">
                            <div class="title">廉正合规</div>
                            <div class="message">
                                天蓬牧运在与客户、业务伙伴、股东等相关各方的业务关系中，致力于遵守所适用法律及最高标准的商业道德行为规范
                            </div>
                            <div class="message">
                                我们要求所有员工在履行职业行为时，恪守合规、道德的基本价值观.如您对天蓬牧运商业行为准则有任何疑问，或希望报告对天蓬牧运员工涉嫌不合法、不道德的任何行为，欢迎您随时联系天蓬牧运廉洁部，并尽可能留下详细举报信息。
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="inner">
                            <div class="email">举报邮箱：</div>
                            <div class="mobile">举报电话：</div>
                            <div class="tip">(以上联络方式仅适用于涉及天蓬牧运员工不合规、不道德行为的咨询或举报。如您对天蓬牧运业务有咨询或申诉需要，请直接使用相关业务的联系途径，拨打我们的客服电话。)</div>
                            <div class="commitment">天蓬牧运承诺，我们会谨慎、公正地处理举报信息，并在法律许可范围内严格确保举报的保密性，同时我们禁止任何非法歧视或报复行为。</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="height: 100px;"></div>
    </Layout>
</template>

<script>
    import Layout from "../components/common/Layout";
    import SectionTitle from "../components/elements/sectionTitle/SectionTitle";

    export default {
        name: 'AdvancePricingPage',
        components: {SectionTitle, Layout}
    }
</script>
<style lang="scss">
    .contact-container {
        margin-top: 45px;
        .title {
            color: #1e65f1 !important;
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 20px;
        }
        .message {
            font-size: 16px;
            color: #848484;
            line-height: 30px;
            margin-bottom: 20px;
        }
        .email, .mobile {
            font-size: 20px;
        }
        .email {
            margin-bottom: 10px;
        }
        .mobile {
            margin-bottom: 20px;
        }
        .tip {
            font-size: 16px;
            color: #848484;
            margin-bottom: 20px;
        }
        .commitment {
            color: #333333;
            font-weight: bold;
        }
    }
</style>