<template>
    <Layout :footer-style="2">
        <div style="height: 20px;"></div>
        <div class="demo-slider-area slider-area bg-transparent slider-style-1">
            <div class="container banner-container">
                <div id="carouselExampleIndicators" class="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-interval="2000"  data-aos="fade-up">
                    <div class="carousel-indicators">
                        <button 
                            type="button" 
                            data-bs-target="#carouselExampleIndicators" 
                            v-for="(item, index) of bannerImgs"
                            :data-bs-slide-to="index" 
                            :class="{active: index == 0}" 
                            aria-current="true" 
                            :aria-label="`Slide ${index+1}`
                        "></button>
                    </div>
                    <div class="carousel-inner">
                        <div class="carousel-item" :class="{active: index == 0}" v-for="(item, index) of bannerImgs">
                            <img style="object-fit: cover;" :src="item.url" class="d-block w-100" alt="...">
                        </div>
                    </div>
                    <button v-if="bannerImgs.length > 1" class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button v-if="bannerImgs.length > 1" class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </div>
        <!-- 专业的牧业运输平台 star -->
        <div class="rs-services services-style8 gray-bg15" style="margin-bottom: 100px;">
            <div class="container container">
                <div class="sec-title text-center mb-55 md-mb-35">
                    <h3 class="title" data-aos="fade-up">
                        专业的牧业运输平台			
                    </h3>
                    <p class="desc" data-aos="fade-up">
                        牧运运输就找天蓬
                    </p>
                </div>
                <div class="row justify-content-between">
                    <div 
                        class="col-1-5 mb-30" 
                        v-for="(item, index) of list"
                        data-aos="fade-up" 
                        :data-aos-dela="index*10" 
                    >
                        <div class="services-item">
                            <div class="services-wrap">
                                <div class="services-top">
                                    <div class="services-title">{{item.title}}</div>
                                    <div class="services-icon">
                                        <img style="object-fit: cover;" class="icon" :src="item.icon" alt="Images">
                                    </div>
                                </div>
                                <div class="services-content">
                                    <p class="services-txt">{{item.text}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 专业的牧业运输平台 end -->
        <!-- 关于我们 star -->
        <div class="demo-slider-area slider-area bg-transparent slider-style-1">
            <div class="container about-container">
                <div class="row">
                    <div class="col-md-3 col-rotate">
                        <div class="about-inner inner-left w-100 h-100">
                            <div class="about-object w-100 h-100">
                                <img style="object-fit: cover;" class="w-100 h-100" src="@/assets/images/home/about_us_text.png" alt="Corporate Image"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="about-inner">
                            <div class="" data-aos="fade-up">
                                <h5 class="about-inner-title" data-aos="fade-up">关于我们</h5>
                                <div class="about-inner-content" data-aos="fade-up">
                                    <span>天蓬牧运是专业的畜牧业运输信息匹配平台，旨在打造一个以猪、牛、羊、马、驴等畜牧业产品为主体的一站式牧业运输平台，为畜牧运输产业双端用户提供高效、便捷、贴心的方案，公司依托软件完善的功能及智能化运算技术，为货主和司机客户提供安心、放心、省心、舒心的线上服务，牧业运输就找天蓬。</span>
                                    <router-link to="/about">
                                        详情>>
                                    </router-link>
                                </div>
                                <div id="carouselExampleIndicators2" class="carousel slide" data-bs-ride="carousel" data-bs-interval="1600"  data-aos="fade-up" style="max-width: 900px; margin: auto;">
                                    <div class="carousel-indicators">
                                        <button 
                                            type="button" 
                                            data-bs-target="#carouselExampleIndicators" 
                                            v-for="(item, index) of aboutBannerImgs"
                                            :data-bs-slide-to="index" 
                                            :class="{active: index == 0}" 
                                            aria-current="true" 
                                            :aria-label="`Slide ${index+1}`
                                        "></button>
                                    </div>
                                    <div class="carousel-inner">
                                        <div class="carousel-item" :class="{active: index == 0}" v-for="(item, index) of aboutBannerImgs">
                                            <img style="object-fit: cover;" :src="item.url" class="d-block w-100" alt="...">
                                        </div>
                                    </div>
                                    <button v-if="aboutBannerImgs.length > 1" class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button v-if="aboutBannerImgs.length > 1" class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 关于我们 end -->
        <!-- 服务对象 star -->
        <div class="demo-slider-area slider-area bg-transparent slider-style-1">
            <div class="container about-container">
                <div class="row">
                    <div class="col-md-3 col-rotate" data-aos="fade-up">
                        <div class="about-inner inner-left w-100 h-100">
                            <div class="about-object w-100 h-100">
                                <img style="object-fit: cover;" class="w-100 h-100" src="@/assets/images/home/service_text.png" alt="Corporate Image"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="about-inner">
                            <div class="" data-aos="fade-up">
                                <h5 class="about-inner-title" data-aos="fade-up">服务对象</h5>
                                <div class="about-inner-content" data-aos="fade-up">
                                    <span>牧运千万里，天蓬零距离。天蓬牧运APP基于互联网、大数据和云计算平台的核心技术，为广大的畜牧场主、贸易货主、养殖集团、食品公司、交易货站、货运公司、货车司机等客户群体提供精准的行业信息和完善的线上服务，推动畜牧运输互联互通，精准高效，省时省力。</span>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="inner text-center">
                                            <div class="" data-aos="fade-up" style="max-width: 900px; margin: auto;">
                                                <img style="object-fit: cover;" src="../assets/images/home/service.png" alt="Corporate Image"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 服务对象 end -->
        <!-- 行业资讯 star -->
        <div class="demo-slider-area slider-area bg-transparent slider-style-1 hyzx">
            <div class="container" style="margin-bottom: 100px;">
                <ContainerTitle title1="行业资讯" title2="INDUSTRY INFORMATION" :fontSize1="34" :fontSize2="22"/>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-8 hyzx-item" data-aos="fade-up">
                        <div class="inner inner-left w-100 h-100">
                            <div class="about-inner-content" data-aos="fade-up">
                                <div class="text-item text-indent" v-for="(item, index) of hangyezixuniList.texts">
                                    {{item.text}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 hyzx-item" data-aos="fade-up">
                        <div class="inner w-100 h-100" style="overflow: hidden;">
                            <img style="object-fit: cover;" class="w-100 h-100 img-hover" :src="hangyezixuniList.imgs[0].url" alt="Corporate Image"/>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-8 hyzx-item" data-aos="fade-up">
                        <div class="inner w-100 h-100" style="overflow: hidden;">
                            <img style="object-fit: cover;" class="w-100 h-100 img-hover" :src="hangyezixuniList.imgs[1].url" alt="Corporate Image"/>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 hyzx-item" data-aos="fade-up">
                        <div class="inner w-100 h-100" style="overflow: hidden;">
                            <img style="object-fit: cover;" class="w-100 h-100 img-hover" :src="hangyezixuniList.imgs[2].url" alt="Corporate Image"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 行业资讯 end -->
        <!-- 公司动态 star -->
        <div class="demo-slider-area slider-area bg-transparent slider-style-1 hyzx">
            <div class="container" style="margin-bottom: 100px;">
                <ContainerTitle title1="公司动态" title2="COMPANY DYNAMICS"  :fontSize1="34" :fontSize2="22"/>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-8 hyzx-item" data-aos="fade-up">
                        <div class="inner inner-left w-100 h-100">
                            <div class="about-inner-content" data-aos="fade-up">
                                <div class="text-item text-indent" v-for="(item, index) of gongsidongtaiList.texts">
                                    {{item.text}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 hyzx-item" data-aos="fade-up">
                        <div class="inner w-100 h-100" style="overflow: hidden;">
                            <img style="object-fit: cover;" class="w-100 h-100 img-hover" :src="gongsidongtaiList.imgs[0].url" alt="Corporate Image"/>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-8 hyzx-item" data-aos="fade-up">
                        <div class="inner w-100 h-100" style="overflow: hidden;">
                            <img style="object-fit: cover;" class="w-100 h-100 img-hover" :src="gongsidongtaiList.imgs[1].url" alt="Corporate Image"/>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 hyzx-item" data-aos="fade-up">
                        <div class="inner w-100 h-100" style="overflow: hidden;">
                            <img style="object-fit: cover;" class="w-100 h-100 img-hover" :src="gongsidongtaiList.imgs[2].url" alt="Corporate Image"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 公司动态 end -->
        <!-- 业务延伸 star -->
        <div class="demo-slider-area slider-area bg-transparent slider-style-1 hyzx">
            <div class="container" style="margin-bottom: 100px;">
                <ContainerTitle title1="业务延伸" title2="BUSINESS" :fontSize1="34" :fontSize2="22"/>
                <div class="row">
                    <div 
                        class="col-6 col-sm-3 col-md-3 col-lg-3 mb-30 ywys" 
                        data-aos="fade-up"
                        v-for="(item, index) of yewuyanshenList"
                    >
                        <div class="inner w-100 h-100">
                            <img style="object-fit: cover;" class="w-100 hovers" :src="item.imgUrl" alt="Corporate Image"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 业务延伸 end -->
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import ContainerTitle from '../components/elements/containerTitle/ContainerTitle'

    export default {
        name: 'Home',
        components: {Layout, ContainerTitle},
        data() {
            return {
                bannerImgs: [
                    {id:1, url: require('@/assets/images/home/banner.png')}
                ],
                aboutBannerImgs: [
                    {id:1, url: require('@/assets/images/home/about_us.png')}
                ],
                list: [
                    {id: 1, title: '范围广', icon: require('@/assets/images/home/fanwei.png'), text: '司机货主双端用户，线上完整互联互通，集全国海量货源，信息平台一体化。'},
                    {id: 2, title: '专业精', icon: require('@/assets/images/home/zhuanye.png'), text: '天蓬团队深耕于畜牧行业产业链，对养殖，贸易，运输，屠宰等版块具备深度解读，有效将资源优势、行业优势相结合，打造专业的畜牧业运输信息平台。'},
                    {id: 3, title: '司机多', icon: require('@/assets/images/home/siji.png'), text: '平台容纳多规格，多尺寸，多车型，多数量的妆也畜牧运输车辆，备案手续齐全，车源庞大，反应时效迅速。'},
                    {id: 4, title: '资源优', icon: require('@/assets/images/home/ziyuan.png'), text: '精确解析全国畜牧运输动向，有效获取全国优质资源，与海量贸易货主，集团公司，物流火葬深度合作，及时发布最新货源，满足各类型畜牧运输车辆的货源需求，将长途调运，短途调配，跨区运输匹配结合，满足定制化运输路线的需求。'},
                    {id: 5, title: '服务好', icon: require('@/assets/images/home/fuwu.png'), text: '专业的技术研发团队，与市场运营团队结合，以完服务及精湛技术，满足不同客户的需求，为双端用户打造优质，便捷，精准的贴心服务。'}
                ],
                hangyezixuniList: {
                    texts: [
                        {id:1, text: '牧运千万里，天蓬零距离。天蓬牧运APP基于互联网、大数据和云计算平台的核心技术，为广大的畜牧场主、贸易货主、养殖集团、食品公司、交易货站、货运公司、货车司机等客户群体提供精准的行业信息和完善的线上服务，推动畜牧运输互联互通，精准高效，省时省力。'},
                        {id:2, text: '牧运千万里，天蓬零距离。天蓬牧运APP基于互联网、大数据和云计算平台的核心技术，为广大的畜牧场主、贸易货主、养殖集团、食品公司、交易货站、货运公司。'},
                        {id:3, text: '牧运千万里，天蓬零距离。天蓬牧运APP基于互联网、大数据和云计算平台的核心技术，为广大的畜牧场主、贸易货主、养殖集团、食品公司、交易货站、货运公司、货车司机等客户群体提供精准的行业信息和完善的线上服务，推动畜牧运输互联互通，精准高效，省时省力。'}
                    ],
                    imgs: [
                        {id: 1, url: require('@/assets/images/home/hangye1.png')},
                        {id: 2, url: require('@/assets/images/home/hangye2.png')},
                        {id: 3, url: require('@/assets/images/home/hangye3.png')},
                    ]
                },
                gongsidongtaiList: {
                    texts: [
                        {id:1, text: '牧运千万里，天蓬零距离。天蓬牧运APP基于互联网、大数据和云计算平台的核心技术，为广大的畜牧场主、贸易货主、养殖集团、食品公司、交易货站、货运公司、货车司机等客户群体提供精准的行业信息和完善的线上服务，推动畜牧运输互联互通，精准高效，省时省力。'},
                        {id:2, text: '牧运千万里，天蓬零距离。天蓬牧运APP基于互联网、大数据和云计算平台的核心技术，为广大的畜牧场主、贸易货主、养殖集团、食品公司、交易货站、货运公司。'},
                        {id:3, text: '牧运千万里，天蓬零距离。天蓬牧运APP基于互联网、大数据和云计算平台的核心技术，为广大的畜牧场主、贸易货主、养殖集团、食品公司、交易货站、货运公司、货车司机等客户群体提供精准的行业信息和完善的线上服务，推动畜牧运输互联互通，精准高效，省时省力。'}
                    ],
                    imgs: [
                        {id: 1, url: require('@/assets/images/home/dongtai1.png')},
                        {id: 2, url: require('@/assets/images/home/dongtai2.png')},
                        {id: 3, url: require('@/assets/images/home/dongtai3.png')},
                    ]
                },
                yewuyanshenList: [
                    {id: 1, imgUrl: require('@/assets/images/home/huaweiyun.png')},
                    {id: 2, imgUrl: require('@/assets/images/home/liuhe.png')},
                    {id: 3, imgUrl: require('@/assets/images/home/muyuan.png')},
                    {id: 4, imgUrl: require('@/assets/images/home/shounong.png')},
                    {id: 5, imgUrl: require('@/assets/images/home/qianxihe.png')},
                    {id: 6, imgUrl: require('@/assets/images/home/zhengda.png')},
                    {id: 7, imgUrl: require('@/assets/images/home/dabeinong.png')},
                    {id: 8, imgUrl: require('@/assets/images/home/wenshi.png')}
                ]
            }
        }
    }
</script>