<template>
    <Layout>
        <div style="height: 20px;"></div>
        <div class="demo-slider-area slider-area bg-transparent slider-style-1">
            <div class="container" style="margin-bottom: 100px;">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner text-center">
                            <div class="" data-aos="fade-up">
                                <img class="w-100" src="../assets/images/recruit/banner.png" alt="Corporate Image"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 加入天蓬 star -->
        <div class="demo-slider-area slider-area bg-transparent slider-style-1">
            <div class="container join-tianpeng px-4">
                <ContainerTitle title1="加入天蓬" title2="JOIN TIANPENG" :fontSize1="26" :fontSize2="20" :isRecrui="true"/>
                <div class="row g-4">
                    <div 
                        class="col-sm-6 col-md-6 col-lg-6" 
                        v-for="(item, index) of classifyList"
                        data-aos="fade-up" 
                        :data-aos-delay="index * 10"
                    >
                        <div class="join-tianpeng-item">
                            <div class="classify">
                                <div class="text">{{item.name}}</div>
                                <div class="icon">
                                    <img :src="item.icon" alt="">
                                </div>
                            </div>
                            <div class="todetail">
                                <router-link to="/recruit">共{{item.stationNum}}个职位></router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 加入天蓬 end -->
        <!-- 工作氛围 star -->
        <div class="demo-slider-area slider-area bg-transparent slider-style-1">
            <div class="container" style="margin-bottom: 100px;">
                <ContainerTitle title1="工作氛围" title2="WORKING ATMOSPHERE" :fontSize1="26" :fontSize2="20"/>
                <ContainerGrid :list="list1"/>
            </div>
        </div>
        <!-- 工作氛围 end -->
        <!-- 团队风采 star -->
        <div class="demo-slider-area slider-area bg-transparent slider-style-1">
            <div class="container" style="margin-bottom: 100px;">
                <ContainerTitle title1="团队风采" title2="TEAM STYLE" :fontSize1="26" :fontSize2="20"/>
                <ContainerGrid :list="tuanduifengcaiList"/>
            </div>
        </div>
        <!-- 团队风采 end -->
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import ContainerTitle from '../components/elements/containerTitle/ContainerTitle'
    import ContainerGrid from '../components/elements/containerGrid/ContainerGrid'

    export default {
        name: 'About',
        components: {Layout, ContainerTitle, ContainerGrid},
        data() {
            return {
                list1: [
                    {img: require('@/assets/images/recruit/fw1.png')},
                    {img: require('@/assets/images/recruit/fw2.png')},
                    {img: require('@/assets/images/recruit/fw3.png')},
                    {img: require('@/assets/images/recruit/fw4.png')}
                ],
                tuanduifengcaiList: [
                    {img: require('@/assets/images/about/shichangbu.png'), text: '市场部'},
                    {img: require('@/assets/images/about/xiaoshoubu.png'), text: '销售部'},
                    {img: require('@/assets/images/about/gongchengbu.png'), text: '工程部'},
                    {img: require('@/assets/images/about/xinxibu.png'), text: '信息部'}
                ],
                classifyList: [
                    {name: '运营类', icon: require('@/assets/images/recruit/yunying.png'), stationNum: 29},
                    {name: '技术开发类', icon: require('@/assets/images/recruit/kaifa.png'), stationNum: 41},
                    {name: '销售/商拓/客服类', icon: require('@/assets/images/recruit/xiaoshou.png'), stationNum: 358},
                    {name: '综合类', icon: require('@/assets/images/recruit/zonghe.png'), stationNum: 14},
                ]
            }
        }
    }
</script>
<style lang="scss">
    .join-tianpeng {
        padding: 20px 20px;
        margin-bottom: 100px;
        .join-tianpeng-item {
            padding: 20px;
            background: #ffffff;
            .classify {
                display: flex;
                align-items: center;
                .text {
                    flex: 1;
                    font-size: 22px;
                    color: #333333;
                    font-weight: bold;
                }
                .icon {
                    width: 45px;
                    height: 45px;
                }
            }
            .todetail {
                a {
                    color: #216cff;
                    font-size: 16px;
                }
            }
        }
        
    }

    .join-tianpeng-item:hover {
        box-shadow: 0 0 0 #cccccc,
        0 0 0 #cccccc,
        0 10px 10px #cccccc,
        0 0 0 #cccccc;
        transition: all .6s ease;
        .icon {
            animation: iconRotate .5s;
        }
    }
</style>