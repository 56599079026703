import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import About from '../views/About'
import Download from '../views/Download'
import Recruit from '../views/Recruit'
import Contact from '../views/Contact'
import ErrorPage from '../views/404'


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: '天蓬牧运-首页',
        },
    },
    {
        path: '/about',
        name: 'About',
        component: About,
        meta: {
            title: '天蓬牧运-关于我们',
        },
    },
    {
        path: '/download',
        name: 'Download',
        component: Download,
        meta: {
            title: '天蓬牧运-软件下载',
        },
    },
    {
        path: '/recruit',
        name: 'Recruit',
        component: Recruit,
        meta: {
            title: '天蓬牧运-人才招聘',
        },
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact,
        meta: {
            title: '天蓬牧运-联系我们',
        },
    },
    {
        path: '/error',
        name: 'Error',
        component: ErrorPage,
        meta: {
            title: '404',
        },
    },
    {
        /**
         * 进行我们的路由匹配，通俗来讲就是比如我们是http://localhost:8080
         * 匹配我们/后面的如果你乱写的话就会重定向我们的404路由
         * 举个例子
         *   { path: '/user-:afterUser(.*)',      redirect: '/notfound},
         * 匹配我们/user后面的内容，如果你乱写的话就会进入404
         */
        path: '/:pathMatch(.*)*',
        redirect: '/error'
    }
    
]

const router = new VueRouter({
    // mode: 'history',
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
