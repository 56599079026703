<template>
    <router-view/>
</template>

<script>
    export default {}
</script>
<style>
    /* @font-face {
		font-family: 'ZhiMangXing-Regular, ZhiMangXing';
		src: url('~@/assets/fonts/ZhiMangXing-Regular.ttf');
	}
    @font-face {
		font-family: 'Alibaba_PuHuiTi_2.0_35_Thin_35_Thin';
		src: url('~@/assets/fonts/Alibaba_PuHuiTi_2.0_35_Thin_35_Thin.ttf');
	}
    @font-face {
		font-family: 'Alibaba_PuHuiTi_2.0_45_Light_45_Light';
		src: url('~@/assets/fonts/Alibaba_PuHuiTi_2.0_45_Light_45_Light.ttf');
	}
    @font-face {
		font-family: 'Alibaba_PuHuiTi_2.0_55_Regular_55_Regular';
		src: url('~@/assets/fonts/Alibaba_PuHuiTi_2.0_55_Regular_55_Regular.ttf');
	}
    @font-face {
		font-family: 'Alibaba_PuHuiTi_2.0_55_Regular_85_Bold';
		src: url('~@/assets/fonts/Alibaba_PuHuiTi_2.0_55_Regular_85_Bold.ttf');
	}
    @font-face {
		font-family: 'Alibaba_PuHuiTi_2.0_65_Medium_65_Medium';
		src: url('~@/assets/fonts/Alibaba_PuHuiTi_2.0_65_Medium_65_Medium.ttf');
	}
    @font-face {
		font-family: 'Alibaba_PuHuiTi_2.0_75_SemiBold_75_SemiBold';
		src: url('~@/assets/fonts/Alibaba_PuHuiTi_2.0_75_SemiBold_75_SemiBold.ttf');
	}
    @font-face {
		font-family: 'Alibaba_PuHuiTi_2.0_95_ExtraBold_95_ExtraBold';
		src: url('~@/assets/fonts/Alibaba_PuHuiTi_2.0_95_ExtraBold_95_ExtraBold.ttf');
	}
    @font-face {
		font-family: 'Alibaba_PuHuiTi_2.0_105_Heavy_105_Heavy';
		src: url('~@/assets/fonts/Alibaba_PuHuiTi_2.0_105_Heavy_105_Heavy.ttf');
	}
    @font-face {
		font-family: 'Alibaba_PuHuiTi_2.0_115_Black_115_Black';
		src: url('~@/assets/fonts/Alibaba_PuHuiTi_2.0_115_Black_115_Black.ttf');
	} */
    /* * {
        font-family: 'Alibaba_PuHuiTi_2.0_55_Regular_55_Regular';
    } */
</style>