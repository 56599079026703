<template>
    <div class="container-grid">
        <div class="row">
            <div 
                class="col-sm-3 col-md-3 col-lg-3"
                v-for="(item, index) of list" 
                data-aos="fade-up" 
                :data-aos-delay="index * 10" 
                style="padding-right: 0;"
            >
                <dl class="inner w-100 h-100">
                    <dt style="overflow: hidden;">
                        <img class="w-100 img-hover" :src="item.img" alt="Corporate Image"/>
                    </dt>
                    <dd>{{item.text}}</dd>
                </dl>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Grid',
        props: {
            title1: {
                type: String,
                default: ''
            },
            title2: {
                type: String,
                default: ''
            },
            list: {
                type: Array,
                default: []
            },
        }
    }
</script>