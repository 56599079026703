<template>
    <div>
        <div class="sec-title text-center mb-55 md-mb-35">
            <h3 class="title2" data-aos="fade-up" data-aos-delay="200" :style="{'fontSize': fontSize1+'px'}">
                {{title1}}			
            </h3>
            <div class="desc2" :class="{'recruit-desc2': isRecrui}" data-aos="fade-up" data-aos-delay="200" :style="{'fontSize': fontSize2+'px'}">
                <div>{{title2}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Grid',
        props: {
            title1: {
                type: String,
                default: ''
            },
            title2: {
                type: String,
                default: ''
            },
            fontSize1: {
                type: Number,
                default: 30
            },
            fontSize2: {
                type: Number,
                default: 20
            },
            isRecrui: {
                type: Boolean,
                default: false
            }
        }
    }
</script>