<template>
    <Layout>
        <div style="height: 20px;"></div>
        <div class="demo-slider-area slider-area bg-transparent slider-style-1">
            <div class="container" style="margin-bottom: 100px;">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner text-center">
                            <div class="" data-aos="fade-up">
                                <img class="w-100" src="../assets/images/about/banner.png" alt="Corporate Image"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 关于我们 star -->
        <div class="demo-slider-area slider-area bg-transparent slider-style-1 hyzx">
            <div class="container" style="margin-bottom: 100px;">
                <ContainerTitle :title1="list.title1" :title2="list.title2" :fontSize1="list.titleFontSize1" :fontSize2="list.titleFontSize2"/>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12" data-aos="fade-up">
                        <div class="inner inner-left w-100 h-100" style="margin-bottom: 40px;">
                            {{list.content}}
                        </div>
                    </div>
                </div>
                <div class="row  g-4" data-aos="fade-up">
                    <div class="col-1-5" v-for="(item, index) of list.imgs">
                        <div class="inner w-100 h-100">
                            <img class="w-100 h-100 img-hover" :src="item.url" alt="Corporate Image"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 关于我们 end -->
        <!-- 服务对象 star -->
        <div class="demo-slider-area slider-area bg-transparent slider-style-1 hyzx">
            <div class="container" style="margin-bottom: 100px;">
                <ContainerTitle title1="服务对象" title2="SERVICE OBJECT" :fontSize1="28" :fontSize2="20"/>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12" data-aos="fade-up">
                        <div class="inner w-100 h-100">
                            <img class="w-100 h-100" src="@/assets/images/about/fuwuduixiang.png" alt="Corporate Image"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 服务对象 end -->
        <!-- 平台优势 star -->
        <div class="demo-slider-area slider-area bg-transparent slider-style-1">
            <div class="container pingtaiyoushi" style="margin-bottom: 100px;">
                <ContainerTitle title1="平台优势" title2="PLATFORM ADVANTANGE" :fontSize1="28" :fontSize2="20"/>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12" data-aos="fade-up">
                        <div class="inner w-100 h-100">
                            <div class="left-msg">
                                <div class="item">
                                    <div class="text">
                                        <p>范围广</p>
                                        <p>双端客户畜牧运输范围互联广泛，形成<br/>全国货源信息集合于平台，随时点击，全国可查</p>
                                    </div>
                                    <div class="number">01</div>
                                </div>
                                <div class="item">
                                    <div class="text">
                                        <p>专业精</p>
                                        <p>天蓬人深耕畜牧行业产业链，具备对养殖，贸易<br/>运输，屠宰等版块的深度解读，有效将资源优势、行业<br/>优势相结合，打造专业畜牧运输信息平台</p>
                                    </div>
                                    <div class="number">02</div>
                                </div>
                                <div class="item">
                                    <div class="text">
                                        <p>司机多</p>
                                        <p>平台容纳多规格，多尺寸，多车型，多数量的专业<br/>畜牧运输车辆，备案手续齐全，车源庞大<br/>反应时效迅速</p>
                                    </div>
                                    <div class="number">03</div>
                                </div>
                            </div>
                            <div class="tpmy-circle"></div>
                            <div class="right-msg">
                                <div class="item">
                                    <div class="number">04</div>
                                    <div class="text">
                                        <p>货源优</p>
                                        <p>
                                            精确解析全国畜牧运输调运动向，抓取全国优质货<br/>
                                            源，与海量贸易货主，集团公司，物流货站<br/>
                                            深度合作，发布优质货源，满足各类型畜牧运输车辆货<br/>
                                            源需求，长途调运，短途调配，省内省外结合，<br/>
                                            货源信息满足司机偏好需求
                                        </p>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="number">05</div>
                                    <div class="text">
                                        <p>技术强</p>
                                        <p>
                                            专业的技术开发团队，与市场运营团队相结合，以完善的<br/>
                                            服务满足客户需求，为双端用户打造优质，便捷，精<br/>
                                            准的个性化需求
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 平台优势 end -->
        <!-- 公司愿景 star -->
        <div class="demo-slider-area slider-area bg-transparent slider-style-1">
            <div class="container gongsiyuanjing" style="margin-bottom: 100px;">
                <ContainerTitle title1="公司愿景" title2="便泽行业，惠及民生" :fontSize1="28" :fontSize2="20"/>
                <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-6 item" data-aos="fade-up" style="padding-right: 0;">
                        <div class="inner w-100 h-100">
                            <img class="w-100 h-100" src="@/assets/images/about/gongsiyuanjing.png" alt="Corporate Image"/>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6 item" data-aos="fade-up" style="padding-left: 0;">
                        <div class="inner w-100 h-100">
                            <div class="gongsishiming">
                                <div class="title">公司使命</div>
                                <div class="item">让货主便捷安心；让司机舒心满意；</div>
                                <div class="item">让合作愉快放心；让生活充满爱心。</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 公司愿景 end -->
        <!-- 团队风采 star -->
        <div class="demo-slider-area slider-area bg-transparent slider-style-1">
            <div class="container" style="margin-bottom: 100px;">
                <ContainerTitle title1="团队风采" title2="TEAM STYLE" :fontSize1="28" :fontSize2="20"/>
                <ContainerGrid :list="tuanduifengcaiList"/>
            </div>
        </div>
        <!-- 团队风采 end -->
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import ContainerTitle from '../components/elements/containerTitle/ContainerTitle'
    import ContainerGrid from '../components/elements/containerGrid/ContainerGrid'

    export default {
        name: 'About',
        components: {Layout, ContainerGrid, ContainerTitle},
        data() {
            return {
                list: {
                    title1: '关于我们',
                    titleFontSize1: 26,
                    title2: 'ABOUT US',
                    titleFontSize2: 20,
                    content: '天蓬牧运是专业的畜牧业运输信息匹配平台，旨在打造一个以猪、牛、羊、马、驴等畜牧产品为主体的一站式牧运运输平台，为畜牧运输产业双端用户提供高效、便捷、贴心的方案，公司依托软件完善的功能及智能化运算技术，为货主和司机客户提供安心、放心、省心、舒心的线上服务，牧业运输就找天蓬。',
                    imgs: [
                        {url: require('@/assets/images/about/zhu.png')},
                        {url: require('@/assets/images/about/niu.png')},
                        {url: require('@/assets/images/about/yang.png')},
                        {url: require('@/assets/images/about/ma.png')},
                        {url: require('@/assets/images/about/lv.png')}
                    ]
                },
                tuanduifengcaiList: [
                    {img: require('@/assets/images/about/shichangbu.png'), text: '市场部'},
                    {img: require('@/assets/images/about/xiaoshoubu.png'), text: '销售部'},
                    {img: require('@/assets/images/about/gongchengbu.png'), text: '工程部'},
                    {img: require('@/assets/images/about/xinxibu.png'), text: '信息部'}
                ]
            }
        }
    }
</script>