<template>
    <!-- Start Copy Right Area  -->
    <div class="copyright-area copyright-style-one">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                    <p class="copyright-text" style="display: flex;align-items: center; justify-content: center;">
                        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">©{{ new Date().getFullYear() }} | 赤峰天蓬网络科技有限公司 | 蒙ICP备2023001517号-2</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <!-- End Copy Right Area  -->
</template>

<script>
    export default {
        name: 'Copyright'
    }
</script>