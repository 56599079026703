<template>
  <div class="logo">
    <router-link :to="url">
      <!-- <img class="logo-light" :src="lightLogo" alt="Corporate Logo" />
      <img class="logo-dark" :src="darkLogo" alt="Corporate Logo" /> -->
      <div class="logo">天蓬牧运</div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  props: {
    url: {
      type: String,
      default: '/'
    },
    lightLogo: {
      type: String,
      default: function () {
        // return require(`@/assets/images/tpmy/logo.png`)
        return require(`@/assets/images/tp_logo.png`)
      }
    },
    darkLogo: {
      type: String,
      default: function () {
        return require(`@/assets/images/tp_logo.png`)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.logo {
  font-size: 23px;
  color: #000;
  font-weight: bold;
}
</style>
