<template>
    <Layout>
        <div style="height: 20px;"></div>
        <div class="demo-slider-area slider-area bg-transparent slider-style-1">
            <div class="container down-container">
                <div class="row">
                    <div class="col-lg-6"></div>
                    <div class="col-lg-6">
                        <div class="inner text-center">
                            <div class="cont" data-aos="fade-up">
                                <!-- <img class="w-100" style="object-fit: cover;" src="@/assets/images/upload/xiazai.png" alt="Corporate Image"/> -->
                                <div class="fonts">
                                    <div class="text1">牧运千万里</div>
                                    <div class="text2">天蓬零距离</div>
                                </div>
                                <div class="downLoad">
                                    <div class="items">
                                        <img src="@/assets/images/downloadCode.png" alt="">
                                        <div>扫码下载司机端</div>
                                    </div>
                                    <div class="items">
                                        <img src="@/assets/images/downloadCode.png" alt="">
                                        <div>扫码下载货主端</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'

    export default {
        name: 'About',
        components: {Layout},
        data() {
            return {
                
            }
        }
    }
</script>
<style lang="scss">
    .down-container {
        // width: 140px;
        height: 100vh;
        background: url(~@/assets/images/upload/xiazai.png) 50% 50%  / cover !important;
        margin-bottom: 60px;
        .cont {
            padding-top: 200px;
            .fonts {
                color: #2b5fe1;
                line-height: 78px;
                font-family: '创客贴金刚体';
                text-align: right;
                .text1 {
                    font-weight: var(--p-medium);
                }
                .text2 {
                    // font-weight: var(--p-light);
                }
            }
            .downLoad {
                .items {
                    color: #216cff;
                    font-weight: var(--p-medium);
                    margin-bottom: 40px;
                    img {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
    
    @media only screen and (max-width: 567px) {
        .cont {
            padding-top: 260px !important;
            .fonts {
                display: none;
            }
            .downLoad {
                text-align: right;
                .items {
                    font-size: 20px;
                    color: #216cff;
                    font-weight: var(--p-medium);
                    // text-align: center;
                    img {
                        margin-bottom: 10px;
                        max-width: 140px;
                        max-height: 140px;
                    }
                }
                .items:first-child {
                    margin-right: 0 !important;
                }
            }
        }
    }
    @media only screen and (min-width: 600px) {
        .cont {
            .fonts {
                .text1 {
                    font-size: 56px;
                    letter-spacing: 8px;
                }
                .text2 {
                    font-size: 42px;
                    letter-spacing: 6px;
                }
            }
            .downLoad {
                margin-top: 100px;
                display: flex;
                justify-content: flex-end;
                .items {
                    max-width: 165px;
                    max-height: 165px;
                    font-size: 18px;
                    color: #216cff;
                    font-weight: var(--p-medium);
                    img {
                        margin-bottom: 10px;
                    }
                }
                .items:first-child {
                    margin-right: 120px;
                }
            }
        }
    }
    @media only screen and (min-width: 768px) {
        .cont {
            .fonts {
                padding-right: 25px;
                .text1 {
                    font-size: 62px;
                    letter-spacing: 8px;
                }
                .text2 {
                    font-size: 52px;
                    letter-spacing: 6px;
                }
            }
            .downLoad {
                margin-top: 100px;
                padding-right: 20px;
                display: flex;
                justify-content: flex-end;
                .items {
                    max-width: 165px;
                    max-height: 165px;
                    font-size: 18px;
                    color: #216cff;
                    font-weight: var(--p-medium);
                    img {
                        margin-bottom: 10px;
                    }
                }
                .items:first-child {
                    margin-right: 120px;
                }
            }
        }
    } 
    @media only screen and (min-width: 992px) {
        .cont {
            padding-top: 200px;
            .fonts {
                color: #2b5fe1;
                line-height: 78px;
                font-family: '创客贴金刚体';
                text-align: right;
                padding-right: 100px;
                .text1 {
                    font-size: 62px;
                    letter-spacing: 8px;
                }
                .text2 {
                    font-size: 52px;
                    letter-spacing: 6px;
                }
            }
            .downLoad {
                margin-top: 100px;
                padding-right: 90px;
                display: flex;
                justify-content: flex-end;
                .items {
                    max-width: 165px;
                    max-height: 165px;
                    font-size: 18px;
                    color: #216cff;
                    font-weight: var(--p-medium);
                    img {
                        margin-bottom: 10px;
                    }
                }
                .items:first-child {
                    margin-right: 120px;
                }
            }
        }
    } 
    @media only screen and (min-width: 1200px) {
        .cont {
            padding-top: 200px;
            .fonts {
                color: #2b5fe1;
                line-height: 78px;
                font-family: '创客贴金刚体';
                text-align: right;
                padding-right: 100px;
                .text1 {
                    font-size: 76px;
                    letter-spacing: 8px;
                }
                .text2 {
                    font-size: 52px;
                    letter-spacing: 6px;
                }
            }
            .downLoad {
                margin-top: 100px;
                padding-right: 90px;
                display: flex;
                justify-content: flex-end;
                .items {
                    max-width: 165px;
                    max-height: 165px;
                    font-size: 20px;
                    color: #216cff;
                    font-weight: var(--p-medium);
                    img {
                        margin-bottom: 10px;
                    }
                }
                .items:first-child {
                    margin-right: 120px;
                }
            }
        }
    }
</style>